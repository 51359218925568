<!-- eslint-disable vue/no-v-html -->
<template>
  <section class="invoice-add-wrapper add-news-wrapper">

    <!-- Col: Left (Invoice Container) -->
    <b-row v-if="loading">
      <b-col
        cols="11"
        class="mx-auto"
      >
        <b-card class="mb-2">
          <b-card-text class="px-5 py-2">
            Loading...
          </b-card-text>
        </b-card>
      </b-col>
    </b-row>
    <b-row
      v-else
      class="invoice-add"
    >
      <b-col
        cols="12"
        xl="9"
        md="8"
      >
        <b-card>
          <b-card-text class="px-5 py-2 mobile-card-text">
            <div style="position: absolute; top: 7px; left: 7px;">
              <div
                :class="`badge badge-light-${news.published ? 'success' : 'danger'} mr-1`"
                style="margin: 0 auto; width: fit-content;"
              >{{ news.published ? 'Published' : 'Not Published' }}</div>
              <span v-if="Array.isArray(getVariantTag(news.tag))">
                <b-badge
                  v-for="(tag, index) in getVariantTag(news.tag)"
                  :key="index"
                  :variant="tag.color"
                  class="mr-1"
                >
                  {{ tag.text }}
                </b-badge>
              </span>
              <b-badge
                v-else
                :variant="getVariantTag(news.tag).color"
              >
                {{ getVariantTag(news.tag).text }}
              </b-badge>
            </div>
            <p class="float-right font-weight-bold">
              {{ moment(news.created_at).format('MMMM DD, YYYY') }} | {{ moment(news.created_at, "HH:mm:ss").format("LT") }}
            </p>
            <h1 class="mb-4 pt-4 text-center">
              {{ news.title }}
            </h1>
            <div
              class="article-inner-body"
              v-html="news.content"
            />
          </b-card-text>
        </b-card>
      </b-col>
      <b-col
        cols="12"
        md="4"
        xl="3"
        class="invoice-actions mt-md-0 mt-2"
      >
        <b-card>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            class="mb-75"
            variant="success"
            block
            :disabled="detailEnabled"
            @click="viewNewsDetail(news.id)"
          >
            <span v-if="detailEnabled">
              <b-spinner
                small
                type="grow"
              />
              Editing...
            </span>
            <span v-else>Edit</span>

          </b-button>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-danger"
            class="mb-75"
            block
            @click="goToList"
          >
            <span>Go to list</span>
          </b-button>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-danger"
            class="mb-75"
            block
            :disabled="deleteEnabled"
            @click="deleteNews(news.title,news.id)"
          >
            <span v-if="detailEnabled">
              <b-spinner
                small
                type="grow"
              />
              Deleting...
            </span>
            <span v-else>Delete</span>
          </b-button>
        </b-card>

      </b-col>
    </b-row>

  </section>
</template>

<script>
/* eslint-disable */
import axios from '@/libs/axios'
import moment from 'moment'
import Ripple from 'vue-ripple-directive'
import {
  BRow, BCol, BCard, BCardText, BButton, BSpinner, BBadge,
} from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BBadge,
    BCardText,
    BButton,
    BSpinner,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      news: [],
      loading: true,
      moment,
      id: 0,
      deleteEnabled: false,
      detailEnabled: false,
    }
  },
  computed: {
    user() {
      return this.$store.state.auth.userData
    },
  },
  mounted() {
    this.viewNews()
  },
  methods: {
    getVariantTag(tags) {
      if (tags === 'Product Updates') {
        return {
          color: 'light-warning',
          text: 'Product Updates',
        }
      }
      if (tags === 'Newsletter') {
        return {
          color: 'light-success',
          text: 'Newsletter',
        }
      }
      if (tags == 'Newsletter,Product Updates' || tags == 'Product Updates,Newsletter') {
        return tags.split(',').map(tag => {
          if (tag === 'Product Updates') {
            return {
              color: 'light-warning',
              text: 'Product Updates',
            }
          }
          if (tag === 'Newsletter') {
            return {
              color: 'light-success',
              text: 'Newsletter',
            }
          }
          return {}
        })
      }

      return {
        color: 'light-secondary',
        text: 'N/A',
      }
    },

    viewNews() {
      const { id } = this.$route.params
      const uri = `/api/news/show/${id}`

      this.loading = true

      axios.get(uri)
        .then(res => {
          this.news = res.data
          this.news.content = this.formatImage(this.news.content)
        })
        .catch(error => { console.log(error) })
        .finally(this.loading = false)
    },
    formatImage(content) {
      content = content.replaceAll("<img ", '<img style="max-width: 100%;height: auto" class="center" ') // eslint-disable-line
      content = content.replaceAll("<a href=\"../blog/2019/07/custom-offer-payout-per-user", "<a href=\"https\:\/\/www\.trafficmanager\.com\/blog\/2019\/07\/custom-offer-payout-per-user\/\"") // eslint-disable-line
      content = content.replaceAll("<a href=\"../blog/2019/07/campaigns-scheduling", "<a href=\"https\:\/\/www\.trafficmanager\.com\/blog\/2019\/07\/campaigns-scheduling\/\"") // eslint-disable-line
      return content
    },
    deleteNews(title, id) {
      this.confirmationAlert(`Are you sure you want to delete this news titled "${title}"?`)
        .then(delres => {
          if (delres.isConfirmed) {
            this.deleteEnabled = true
            const uri = `/api/news/destroy/${id}`
            axios.delete(uri)
              .then(res => {
                if (res.data.action === 'OK') this.$router.push('/news/list').catch(error => { console.log(error) })
                this.deleteEnabled = false
              })
              .catch(error => { console.log(error) })
          }
        })
    },
    viewNewsDetail(id) {
      const uri = `/news/edit/${id}`
      this.$router.push(uri).catch(error => { console.log(error) })
    },
    goToList() {
      const uri = '/news/list'
      this.$router.push(uri).catch(error => { console.log(error) })
    },
  },
}
</script>
<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
@import '~@core/scss/base/components/variables-dark';
</style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/quill.scss';
.invoice-add-wrapper {
  .add-new-client-header {
    padding: $options-padding-y $options-padding-x;
    color: $success;

    &:hover {
      background-color: rgba($success, 0.12);
    }
  }

  .mobile-card-text {
    text-align: justify;
  }
}

.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

/* iphone 12/13 pro max - android pro max*/
@media only screen and (max-width: 428px)  {
  .mobile-card-text {
    padding: 5px !important;
  }
  p img{
    height: 100% !important;
    width: 100% !important;
    object-fit: contain
  }
}

/* iphone 12/13 pro - android pro*/
@media only screen and (max-width: 415px)  {

}

/* iphone 12/13 pro - android pro*/
@media only screen and (max-width: 395px)  {

}

/* iphone 12/13 pro - android pro*/
@media only screen and (max-width: 376px)  {

}

/* smaller phone */
@media only screen and (max-width: 365px)  {

}
</style>
